<template>
   <b-nav-item-dropdown right class="notification-list" menu-class="dropdown-lg">
      <template slot="button-content">
        <span class="nav-link  dropdown-toggle">
          <i class="fe-bell noti-icon"></i>
          <span v-if="unreadNotifications.length" class="badge badge-danger rounded-circle noti-icon-badge">{{unreadNotifications.length}}</span>
        </span>
      </template>
      <a href="javascript:void(0);" class="dropdown-item noti-title">
          <h5 class="m-0">
              <span class="float-right">
              </span>
              Notifications
          </h5>
      </a>

      <simplebar style="max-height: 230px">
        <div v-for="(notif, index) in displayNotifs" :key="index">
          <a href="javascript:void(0)"  @click.prevent="navigateTo(notif)" class="dropdown-item notify-item"
            :class="{'active': !notif.is_seen }">
            <div class="notify-icon bg-soft-primary text-primary">
              <i class="mdi mdi-message-text"></i>
            </div>
            <p class="notify-details">
              <span>{{ notif.title }}</span>
              <small class="text-muted d-block font-10">{{ notif.content | truncate(50)  }}</small>
              <small class="text-muted d-block font-10">{{ notif.created_at | date_ago }}</small>
            </p>
          </a>
        </div>
      </simplebar>

      <a
        href="javascript:void(0);"
        class="dropdown-item text-center text-primary notify-item notify-all"
        @click.prevent="$router.push({path: '/notifications'})">
          View all
          <i class="fi-arrow-right"></i>
      </a>
  </b-nav-item-dropdown>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    notifications(){
      return this.$store.state.notification.list
    },
    unreadNotifications() {
      return this.notifications.filter((notif) => (notif.is_seen == 0))
    },
    readNotifications() {
      return this.notifications.filter((notif) => (notif.is_seen == 1))
    },
    displayNotifs(){
      return (this.unreadNotifications.length > 1) ?
        [...this.unreadNotifications.slice(0,10), ...this.readNotifications.slice(0,10)] :
        this.notifications.slice(0,10)
    },
    activeUser() {
      return this.$store.state.AppActiveUser
    }
  },
  created(){
    this.$store.dispatch("notification/fetchNotifications")
  },
  methods:{
    navigateTo(notif){
      if(!notif.is_seen){
        this.$store.dispatch("notification/markNotifAsRead", notif.id);
      }
      this.$router.push({ path: notif.url });
    },
    actionExecutor(data){
      if(data.action == "fetch-order"){
        let order = this.$store.state.orderList.order;
        if(order && order.id == data.order_id){
          this.$store.dispatch("orderList/fetchOrder", data.order_id)
        }
      }
      if(data.action == "fetch-orders"){
        this.$store.dispatch("orderList/fetchOrders")
      }
    }
  },
  mounted(){
    // Echo.channel("admin-notify-channel")
    //   .listen('NotificationEvent', (e) => {
    //     this.$store.commit('notification/ADD_NOTIFICATION',e.data)
    //     this.actionExecutor(e.data)
    // });
  }
}

</script>

<style>
.notification-list .dropdown-toggle{
    position: relative;
}
</style>
