

<template>
<li>
  <div class="d-none d-lg-block">
    <form class="app-search">
        <div class="app-search-box">
            <div class="input-group">
                <input type="search" class="form-control" :placeholder="$t('SearchText' || 'Search Anything')" id="top-search" />
                <div class="input-group-append">
                    <button class="btn" type="submit">
                        <i class="fe-search"></i>
                    </button>
                </div>
            </div>
        </div>
    </form>
  </div>
  <div class="dropdown d-inline-block d-lg-none">
      <a class="nav-link dropdown-toggle arrow-none waves-effect waves-light" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
      <i class="fe-search noti-icon"></i>
    </a>
    <div class="dropdown-menu dropdown-lg dropdown-menu-right p-0">
        <form class="p-3">
            <input type="text" class="form-control" :placeholder="$t('SearchText' || 'Search Anything')" aria-label="Recipient's username" />
        </form>
    </div>
  </div>

  <!-- <b-nav-item-dropdown class="dropdown-mega d-none d-xl-block" variant="black" menu-class="dropdown-megamenu">
      <template v-slot:button-content>
          {{ $t('navbar.dropdown.megamenu.text') }}
          <i class="mdi mdi-chevron-down"></i>
      </template>

      <div class="row">
          <div class="col-sm-8">
              <div class="row">
                  <div class="col-md-4">
                      <h5 class="text-dark mt-0">{{ $t('navbar.dropdown.megamenu.uicontent.title') }}</h5>
                      <ul class="list-unstyled megamenu-list">
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.widgets') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.nestable') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.rangeslider') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.masonry') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.sweetalert') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.treeview') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.tour') }}</a>
                          </li>
                      </ul>
                  </div>

                  <div class="col-md-4">
                      <h5 class="text-dark mt-0">{{ $t('navbar.dropdown.megamenu.application.title') }}</h5>
                      <ul class="list-unstyled megamenu-list">
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.ecommerce') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.crm') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.email') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.calendar') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.contacts') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.tasks') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.template') }}</a>
                          </li>
                      </ul>
                  </div>

                  <div class="col-md-4">
                      <h5 class="text-dark mt-0">{{ $t('navbar.dropdown.megamenu.extrapages.title') }}</h5>
                      <ul class="list-unstyled megamenu-list">
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.leftsidebaruser') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.collapsed') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.leftsidebar') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.header') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.search') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.gallery') }}</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.maintenance') }}</a>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
          <div class="col-sm-4">
              <div class="text-center mt-3">
                  <h3 class="text-dark">{{ $t('navbar.dropdown.megamenu.discount') }}</h3>
                  <h4>{{ $t('navbar.dropdown.megamenu.subtext') }}</h4>
                  <button class="btn btn-primary btn-rounded mt-3">{{ $t('navbar.dropdown.megamenu.buttontext') }}</button>
              </div>
          </div>
      </div>
  </b-nav-item-dropdown> -->
</li>

</template>

<script>
export default {
  data(){
    return{
      prependUrl: '/images/country_flags/',

      languages: [
        {
            flag: "/images/flags/us.jpg",
            language: "en",
            title: "English",
        },
        {
            flag: "/images/flags/french.jpg",
            language: "fr",
            title: "French",
        },
        {
            flag: "/images/flags/spain.jpg",
            language: "de",
            title: "German",
        },
        {
            flag: "/images/flags/arabic.png",
            language: "pt",
            title: "Portuguese",
        },
      ],
      current_language: this.$i18n.locale,
      text: null,
      flag: null,
      value: null,
    }
  },

  methods: {
    updateLocale(locale) {
      this.$i18n.locale = locale;
    },

    setLanguage(locale, country, flag) {
        this.$i18n.locale = locale;
        this.current_language = locale;
        this.text = country;
        this.flag = flag;
    },
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === this.$i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
  },
}
</script>