
<template>
<div class="app-wrap">
    <!-- Begin page -->
    <div id="wrapper">
      <Sidebar :type="layout.leftSidebarType" :width="layout.layoutWidth" :size="layout.leftSidebarSize" :menu="layout.menuPosition" :topbar="layout.topbar" />
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="content-page px-0 px-md-2">

          <div class="content">
              <Topbar />
              <!-- Start Content-->
              <div class="container-fluid">
                  <page-header :title="$route.meta.pageTitle" :items="$route.meta.breadcrumb" />
                  <transition name="fade" mode="out-in">
                      <router-view />
                  </transition>
              </div>
          </div>
          <Footer />
      </div>
    </div>
</div>
</template>

<script>

import themeConfig    from '@/themeConfig.js'
import PageHeader from '@/components/app/PageHeader.vue'
import Sidebar from '@/components/app/LeftSidebar.vue'
import Topbar from '@/components/app/Topbar.vue'
import Footer from '@/components/app/Footer.vue'


export default {
  components: {
    PageHeader,
    Sidebar,
    Topbar,
    Footer
  },
  computed: {
    layout()  { return this.$store.state.layout },
  },
  methods: {
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    toggleMenu() {
      this.isMenuCondensed = !this.isMenuCondensed;
      if (this.isMenuCondensed)
        document.body.setAttribute("data-sidebar-size", "condensed");
      else document.body.removeAttribute("data-sidebar-size", "condensed");
      if (window.screen.width >= 992) {
        this.$router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
      } else {
        document.body.setAttribute("data-sidebar-size", "default");
        document.body.classList.toggle("sidebar-enable");
        this.$router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
      }
    }
  },
  created() {
    this.$store.dispatch("orderList/fetchOrders")
  },
  mounted() {
    document.body.setAttribute("data-layout-mode", "detached");
    document.body.setAttribute("data-sidebar-showuser", "true");
    if (this.$route.query.layout) {
      this.$store.dispatch('layout/changeLayoutType', {
        layoutType: this.$route.query.layout
      })
    }
  }

}
</script>

<style type="text/css">
</style>
