<template>
<!-- start page title -->
<div class="row">
    <div class="col-12">
        <div class="page-title-box" :class="{'page-title-box-alt': layout.layoutType !== 'vertical' && layout.layoutType !== 'two-column'}">
            <h4 class="page-title">{{ title }}</h4>
            <div class="page-title-right">
                <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
            </div>
        </div>
    </div>
</div>
<!-- end page title -->
</template>

<script>
/**
 * Page-header component
 */
export default {
    name: "page-header",
    props: {
        title: {
            type: String,
            default: ""
        },
        items: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    computed: {
        layout()  { return this.$store.state.layout },
    },
};
</script>
